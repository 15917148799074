<template>
  <section id="suppliers-orders">
    <b-card>
      <b-row>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="CalendarIcon" />
            </b-input-group-prepend>
            <flat-pickr v-model="dateRange" placeholder="Date Range" class="form-control" :config="{ mode: 'range' }"
              @on-change="changeDateRange" />
          </b-input-group>
        </b-col>
        <b-col xl="6" md="6">
          <b-input-group class="input-group-merge">
            <b-input-group-prepend is-text>
              <feather-icon icon="SearchIcon" />
            </b-input-group-prepend>
            <b-form-input v-model="searchValue" placeholder="Search" @keyup.enter="searchOrder" />
          </b-input-group>
        </b-col>
      </b-row>
      <br>
      <b-row>
        <b-col xl="6" md="6">
          <v-select v-model="selectedSupplier" label="title" placeholder="Supplier" :options="allSuppliers"
            @input="changeSelectedSupplier" />
        </b-col>
      </b-row>
    </b-card>
    <b-overlay :show="showSupordersTable" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Orders
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-orders" />
          </h4>
          <b-popover target="popover-orders" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="supordersTable.currentPage"
          :items="supordersTable.items" :fields="supordersTable.fields" :sort-by.sync="supordersTable.sortBy"
          :sort-desc.sync="supordersTable.sortDesc" :sort-direction="supordersTable.sortDirection"
          :filter="supordersTable.filter" :filter-included-fields="supordersTable.filterOn" @sort-changed="sortChanged"
          @row-clicked="supordersTableRowClicked">
          <template #cell(pdf_invoices)="data">
            <b-badge v-for="(item, index) in data.item.pdf_invoices" :key="index" variant="light-primary">
              <div v-if="item.pk">
                {{ item.filename }}
              </div>
            </b-badge>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="supordersTable.currentPage" :total-rows="supordersTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleSupordersTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="suporder-details-modal" size="xl" title="Supplier Order Details" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay :show="showSuporderDetails" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <b-card no-body>
          <b-card-header>
            <h4 class="mb-0">
              Order positions
              <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-order-positions" />
            </h4>
            <b-popover target="popover-order-positions" triggers="hover" placement="bottom">
              <span>No data</span>
            </b-popover>
          </b-card-header>
          <b-table striped hover responsive class="position-relative" :current-page="suporderPossTable.currentPage"
            :items="suporderPossTable.items" :fields="suporderPossTable.fields" :sort-by.sync="suporderPossTable.sortBy"
            :sort-desc.sync="suporderPossTable.sortDesc" :sort-direction="suporderPossTable.sortDirection" />
        </b-card>
      </b-overlay>
      <b-overlay :show="showPDFInvoiceDetails" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <div v-if="showPDFInvoiceFile">
          <div v-for="(PDFInvoiceFile, index) in PDFInvoiceFiles" :key="index">
            <hr>
            <b-row>
              <b-col xl="6" md="6">
                <embed :src="PDFInvoiceFile.data.encoded_pdf" width="100%" height="800px">
              </b-col>
              <b-col xl="6" md="6">
                {{ PDFInvoiceFile.data.parsed_data.data }}
              </b-col>
            </b-row>
          </div>
        </div>
      </b-overlay>
    </b-modal>
    <b-overlay :show="showPDFInvoicesTable" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            PDF invoices
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-pdf-invoices" />
          </h4>
          <b-popover target="popover-pdf-invoices" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
          <b-card-text class="font-medium-5 mb-0">
            <b-overlay :show="showExportStart" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
              <feather-icon icon="DownloadIcon" size="21" class="text-muted cursor-pointer"
                @click="downloadZipPDFInvoices" />
            </b-overlay>
          </b-card-text>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="PDFInvoicesTable.currentPage"
          :items="PDFInvoicesTable.items" :fields="PDFInvoicesTable.fields" :sort-by.sync="PDFInvoicesTable.sortBy"
          :sort-desc.sync="PDFInvoicesTable.sortDesc" :sort-direction="PDFInvoicesTable.sortDirection"
          :filter="PDFInvoicesTable.filter" :filter-included-fields="PDFInvoicesTable.filterOn"
          @row-clicked="PDFInvoicesTableRowClicked">
          <template #cell(name)="data">
            <b-badge :key="data.item.id" variant="light-primary">
              {{ data.item.name }}
            </b-badge>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="PDFInvoicesTable.currentPage" :total-rows="PDFInvoicesTable.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handlePDFInvoicesTablePageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
    </b-overlay>
    <b-modal ref="pdf-invoice-details-modal" size="xl" title="PDF Invoice Details" cancel-title="Close"
      cancel-variant="outline-secondary" :no-close-on-backdrop="true">
      <b-overlay :show="showPDFInvoiceDetails" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
        <div v-for="(PDFInvoiceFile, index) in PDFInvoiceFiles" :key="index">
          <hr>
          <b-row>
            <b-col xl="6">
              <embed :src="PDFInvoiceFile.data.encoded_pdf" width="100%" height="800px">
            </b-col>
            <b-col xl="6">
              <div v-if="PDFInvoiceFile.data.parsed_data">
                {{ PDFInvoiceFile.data.parsed_data.data }}
              </div>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BTable,
  BBadge,
  BPagination,
  BCardBody,
  BCardHeader,
  BCardText,
  BPopover,
  BCard,
  BOverlay,
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BModal,
  BButton,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import axios from 'axios';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const currentDate = new Date();
const twelveMonthsAgo = new Date();
twelveMonthsAgo.setMonth(currentDate.getMonth() - 11);
const formatMonth = (date) => String(date.getMonth() + 1).padStart(2, '0');
const formatDate = (date) => String(date.getDate()).padStart(2, '0');

export default {
  components: {
    BRow,
    BCol,
    BTable,
    BBadge,
    BPagination,
    BCard,
    BCardBody,
    BCardHeader,
    BCardText,
    BPopover,
    BOverlay,
    BInputGroup,
    BInputGroupPrepend,
    BFormInput,
    BModal,
    BButton,
    vSelect,
    flatPickr,
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSupordersTable: true,
      showPDFInvoicesTable: true,
      showSuporderDetails: true,
      showPDFInvoiceDetails: true,
      showPDFInvoiceFile: false,
      showExportStart: false,
      oldDateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      dateRange: `${twelveMonthsAgo.getFullYear()}-${formatMonth(twelveMonthsAgo)}-01 to ${currentDate.getFullYear()}-${formatMonth(currentDate)}-${formatDate(currentDate)}`,
      searchValue: '',
      selectedSupplier: [],
      allSuppliers: [],
      PDFInvoiceFiles: [],
      queryParamsOrders: {},
      queryParamsPDFInvoices: {},
      supordersTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'order_date',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'order_no', label: 'order no.', sortable: true },
          { key: 'invoice_no', label: 'invoice no.', sortable: true },
          { key: 'supinvoice_no', label: 'supplier invoice no.', sortable: true },
          { key: 'supplier', label: 'supplier', sortable: true },
          {
            key: 'order_amount',
            label: 'order amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment_amount',
            label: 'payment amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'payment_date',
            label: 'payment date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'order_date',
            label: 'order date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'delivery_date',
            label: 'delivery date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          { key: 'status', label: 'status', sortable: true },
          { key: 'pdf_invoices', label: 'pdf invoices', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      suporderPossTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'item_sku',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'item_sku', label: 'item sku', sortable: true },
          { key: 'name', label: 'name', sortable: true },
          { key: 'qty', label: 'qty', sortable: true },
          {
            key: 'price',
            label: 'price',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'tax',
            label: 'tax',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatNumber(val)} %`;
              } else {
                return '';
              }
            },
          },
          {
            key: 'tax_amount',
            label: 'tax amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      PDFInvoicesTable: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'id',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'id', label: 'id', sortable: true },
          {
            key: 'parsed_data.data.order_no',
            label: 'order no.',
            sortable: true,
            formatter: val => {
              if (val && val.length > 0) {
                return `${val}`;
              }
              return '';
            },
          },
          {
            key: 'parsed_data.data.invoice_no',
            label: 'supplier invoice no.',
            sortable: true,
          },
          {
            key: 'parsed_data.data.supplier',
            label: 'supplier',
            sortable: true,
          },
          {
            key: 'parsed_data.data.total_amount',
            label: 'total amount',
            sortable: true,
            formatter: val => {
              if (val !== null) {
                return `${this.$formatCurrencyNumber(val)}`;
              } else {
                return '';
              }
            },
          },
          { key: 'name', label: 'filename', sortable: true },
          {
            key: 'parsed_data.document_date',
            label: 'document date',
            sortable: true,
            formatter: value => {
              const new_date = new Date(value);
              if (value) {
                return new_date.toLocaleDateString('de-DE');
              }
              return '';
            },
          },
          {
            key: 'suporders_numbers',
            label: 'matched',
            sortable: true,
            formatter: val => {
              if (val && val.length > 0) {
                return `${val}`;
              }
              return '';
            },
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
    }
  },
  async created() {
    const dateRange = this.dateRange.split(' to ');
    this.queryParamsOrders.from_date = dateRange[0];
    this.queryParamsOrders.to_date = dateRange[1];
    this.queryParamsPDFInvoices.from_date = dateRange[0];
    this.queryParamsPDFInvoices.to_date = dateRange[1];

    try {
      await this.getAllSuppliers();
      await this.getSuporders();
      await this.getPDFInvoices();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getAllSuppliers() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/all-suppliers/`, {});
        this.allSuppliers = response.data.results.map(item => item.value);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async getSuporders() {
      this.showSupordersTable = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/suporders/`, this.queryParamsOrders);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.supordersTable.items = results;
          this.supordersTable.totalRows = results[0].count * 2;
        }
        else {
          this.supordersTable.items = [];
          this.supordersTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showSupordersTable = false;
      }
    },
    async getSuporderPoss(orderNo) {
      this.showSuporderDetails = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/suporder/`, { order_no: orderNo });
        this.suporderPossTable.items = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showSuporderDetails = false;
      }
    },
    async getPDFInvoices() {
      this.showPDFInvoicesTable = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/pdf-invoices/`, this.queryParamsPDFInvoices);
        const results = response.data.results;
        if (Array.isArray(results) && results.length > 0) {
          this.PDFInvoicesTable.items = results;
          this.PDFInvoicesTable.totalRows = response.data.count * 2;
        }
        else {
          this.PDFInvoicesTable.items = [];
          this.PDFInvoicesTable.totalRows = 0;
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showPDFInvoicesTable = false;
      }
    },
    async getPDFInvoiceFiles(PDFInvoiceIDs) {
      this.showPDFInvoiceDetails = true;
      const requests = [];
      PDFInvoiceIDs.forEach(element => {
        requests.push(axios.get(`${process.env.VUE_APP_ROOT_API}/pdf-invoices/${element}/`, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        }))
      })
      try {
        const response = await axios.all(requests);
        this.PDFInvoiceFiles = response;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showPDFInvoiceDetails = false;
      }
    },
    async downloadZipPDFInvoices() {
      try {
        this.showExportStart = true;
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/download-zip-pdf-invoices/`, this.queryParamsPDFInvoices);
        this.showExportStart = false;
        this.showToast('success', 'Download started')
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'pdf_invoices.zip');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      }
    },
    async changeDateRange() {
      const dateRange = this.dateRange.split(' to ');
      if (dateRange.length > 1 && this.dateRange != this.oldDateRange) {
        this.queryParamsOrders.from_date = dateRange[0];
        this.queryParamsOrders.to_date = dateRange[1];
        this.queryParamsPDFInvoices.from_date = dateRange[0];
        this.queryParamsPDFInvoices.to_date = dateRange[1];
        await this.getSuporders();
        await this.getPDFInvoices();
        this.oldDateRange = this.dateRange;
      }
    },
    async searchOrder() {
      this.queryParamsOrders.page = 1;
      this.queryParamsOrders.search = '%' + this.searchValue.toLowerCase() + '%';
      this.queryParamsPDFInvoices.page = 1;
      this.queryParamsPDFInvoices.search = '%' + this.searchValue.toLowerCase() + '%';
      await this.getSuporders();
      await this.getPDFInvoices();
    },
    async changeSelectedSupplier() {
      if (this.dateRange) {
        const dateRange = this.dateRange.split(' to ');
        this.queryParamsOrders.from_date = dateRange[0];
        this.queryParamsOrders.to_date = dateRange[1];
      }
      this.queryParamsOrders.selected_supplier = this.selectedSupplier;
      this.queryParamsPDFInvoices.selected_supplier = this.selectedSupplier;
      await this.getSuporders();
      await this.getPDFInvoices();
    },
    async sortChanged(value) {
      if (value.sortDesc === true) {
        this.queryParamsOrders.ordering = `-${value.sortBy}`;
      } else {
        this.queryParamsOrders.ordering = value.sortBy;
      }
      await this.getSuporders();
    },
    async handleSupordersTablePageChange(value) {
      this.queryParamsOrders.page = value;
      await this.getSuporders();
    },
    async handlePDFInvoicesTablePageChange(value) {
      this.queryParamsPDFInvoices.page = value;
      await this.getPDFInvoices();
    },
    supordersTableRowClicked(row) {
      this.PDFInvoiceFiles = [];
      this.getSuporderPoss(row.order_no);
      if (row.pdf_invoices.length > 0 && row.pdf_invoices[0].pk !== null) {
        this.getPDFInvoiceFiles(row.pdf_invoices.map(item => item.pk));
        this.showPDFInvoiceFile = true;
      } else {
        this.showPDFInvoiceDetails = false;
        this.showPDFInvoiceFile = false;
      }
      this.$refs['suporder-details-modal'].show();
    },
    PDFInvoicesTableRowClicked(row) {
      this.PDFInvoiceFiles = [];
      this.getPDFInvoiceFiles([row.id]);
      this.$refs['pdf-invoice-details-modal'].show();
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
